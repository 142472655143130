import React from "react";
import styled from "styled-components";
import Centre from "./components/Centre/Centre";
import LeftBar from "./components/LeftBar/LeftBar";
import RightBar from "./components/RightBar/RightBar";
import { DataProvider } from "./contexts/DataContext";
import gql from "graphql-tag";
import {
  ApolloClient,
  ApolloProvider,
  createHttpLink,
  InMemoryCache,
  useQuery,
} from "@apollo/client";
const Main = styled.div`
  display: flex;
  background-color: #262626;
  width: 1920px;
  height: 1080px;
`;

const httpLink = createHttpLink({
  uri:
  process.env.REACT_APP_WSM_API + "/graphql"
});
console.log(process.env.REACT_APP_PROJECT_SERVER + "/graphql");
const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: httpLink,
});

function App() {
  return (
    <ApolloProvider client={client}>
      <DataProvider>
        <Main>
          <LeftBar />
          <Centre />
          <RightBar />
        </Main>
      </DataProvider>
    </ApolloProvider>
  );
}

export default App;
