import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { DataContext } from "../../contexts/DataContext";
import moment from "moment";
const Main = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
`;
const Top = styled.div`
  display: flex;
  font-size: 0.6em;
  font-weight: 100;
  margin-bottom: 0.5em;
`;
const Rows = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 28px;
`;
const Row = styled.div`
  display: flex;
  padding-bottom: ${(props) => (props.smaller ? "2px" : "7px")};
  font-size: ${(props) => (props.smaller ? "0.6em" : "0.8em")};
`;
const Position = styled.div`
  width: 70px;
`;
const Name = styled.div`
  width: 170px;
`;
const Points = styled.div`
  width: 150px;
`;
const Country = styled.div`
  width: 100px;
`;
const Objects = styled.div`
  min-width: 340px;
  display: flex;
  justify-content: center;
  font-size: 15px;
`;
const Table = styled.div`
  display: flex;
`;
export default function LeadersMaxDistanceGroup({ group, scoring, event }) {
  
  function sort(a, b) {
    let reps_1 = parseFloat(a.scoring?.distance) || 0;
    let reps_2 = parseFloat(b.scoring?.distance) || 0;
    if (reps_1 < reps_2) {
      return 1;
    } else if (reps_1 > reps_2) {
      return -1;
    }
  }

  return (
    <Main>
      <Top>
        <Position></Position>
        <Name></Name>


        <Objects>Distance (m)</Objects>
      </Top>
      <Table>
        <Rows>
          {scoring
            ?.sort(sort)
            ?.slice(0, 10)
            ?.map((row, index) => {
              return (
                <Row>
                  <Position>{index + 1}.</Position>
                  <Name>{row.player?.first_name[0]}. {row.player.last_name}</Name>
                  {/* <Country>{row.player.nationality.code}</Country> */}

                  <Objects>{row.scoring?.distance}</Objects>
                </Row>
              );
            })}
        </Rows>
        <Rows smaller>
          {scoring
            ?.sort(sort)
            .slice(10)
            .slice(-20)
            .map((row, index) => {
              return (
                <Row smaller>
                  <Position>{index + 11}.</Position>
                  <Name>{row.player?.first_name[0]}. {row.player.last_name}</Name>
                  {/* <Country>{row.player.nationality.code}</Country> */}

                  <Objects>{row.scoring?.distance}</Objects>
                </Row>
              );
            })}
        </Rows>
      </Table>
    </Main>
  );
}
