import React, { useState, useEffect, useContext } from "react";
import gql from "graphql-tag";
import { getApolloContext } from "@apollo/client";
const RESULTS = gql`
  query Group {
    results {
      competition {
        _id
      }
      group {
        _id
        name
      }

      rounds {
        status
        round {
          _id

          event {
            _id
            event_type
            name
            unit_name
            objects
          }

          type

          order

          date

          time
        }

        standings {
          player {
            first_name
            nationality
            last_name
          }
          overall_status
          overall_position

          total_points

          points

          position

          scoring
        }
      }
    }
  }
`;

const DISPLAYS = gql`
  query Displays {
    displays {
      _id
      holding
      event {
        _id
      }
      competition {
        _id
      }
      group {
        _id
      }
      round {
        _id
        type
        competition {
          _id
        }
      }
    }
  }
`;

const DataContext = React.createContext([{}, () => {}]);

const DataProvider = (props) => {
  const { client } = useContext(getApolloContext());
  const [competition, setCompetition] = useState();
  useEffect(() => {
    client
      .query({
        query: RESULTS,
        fetchPolicy: "network-only",
      })
      .then((response) => {
        return setCompetition(
          JSON.parse(JSON.stringify(response.data.results)).sort(
            (a, b) => a.group?.name - b.group?.name
          )
        );
      })
      .catch((err) => console.error(err));
    let interval = setInterval(() => {
      client
        .query({
          query: RESULTS,
          fetchPolicy: "network-only",
        })
        .then((response) => {
          return setCompetition(
            JSON.parse(JSON.stringify(response.data.results)).sort(
              (a, b) => a.group?.name - b.group?.name
            )
          );
        })
        .catch((err) => console.error(err));
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [client]);

  function getDisplay() {
    return client
      .query({
        query: DISPLAYS,
        fetchPolicy: "network-only",
      })
      .then((response) => {
        return response.data.displays;
      })
      .catch((err) => console.error(err));
  }

  return (
    <DataContext.Provider
      value={{
        competition,
        getDisplay,
      }}
    >
      {props.children}
    </DataContext.Provider>
  );
};
export { DataContext, DataProvider };
