import React from "react";
import styled from "styled-components";

const Main = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 40px;
`;
const Top = styled.div`
  display: flex;
  font-size: 25px;
  font-weight: 100;
`;
const Rows = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 28px;
`;
const Row = styled.div`
  display: flex;
  padding-bottom: 5px;
  font-size: 1.2em;
  :nth-child(1) {
    color: ${(props) => (props.smaller ? "" : "#fecc43")};
  }
`;
const Position = styled.div`
  width: 70px;
`;
const Name = styled.div`
  width: 500px;
`;
const Points = styled.div`
  /* width: 50px; */
  margin-left: auto;
`;
const Country = styled.div`
  width: 100px;
`;

const Order = styled.div`
  min-width: 100px;
  text-align: center;
`;
export default function MaxWeightGroup({ event, scoring }) {
  return (
    <Main>
      <Top>
        <Position>#</Position>
        <Order>Order</Order>
        <Order>Lane</Order>
        <Name></Name>

        <Country></Country>

        <Points>{event?.name === 'Keg Toss' ? 'Height' : 'Weight'}</Points>
      </Top>
      <Rows>
        {scoring?.filter(p => p.overall_status !== 'WD')
          .sort((a, b) => {

            if ((a?.position || 999) > (b?.position || 999)) {
              return 1;
            } else if ((a?.position || 999) < (b?.position || 999)) {
              return -1;
            }

            if (parseInt(a?.scoring?.order || 999) > parseInt(b?.scoring?.order || 999)) {
              return 1
            } else if (parseInt(a?.scoring?.order || 999) < parseInt(b?.scoring?.order || 999)) {
              return -1
            }
            if (a?.scoring?.lane > b?.scoring?.lane) {
              return 1
            } else if (a?.scoring?.lane < b?.scoring?.lane) {
              return -1
            }
          })?.map((row, index) => {
            return (
              <Row>
                <Position>
                  {row.overall_status}
                  {row.overall_status !== "WD" && row.tied && "T"}
                  {row.overall_status !== "WD" && row.position}.
                </Position>

                <Order>{row.scoring?.order}</Order>
                <Order>{row.scoring?.lane}</Order>
                <Name>
                  {row.player.first_name} {row.player.last_name}
                </Name>
                <Country>{row.player.nationality}</Country>

                <Points style={{ fontSize: '20px' }}>
                  {
                    event?.objects?.[
                      row?.scoring?.objects
                        ?.filter((o) => o.success)
                        ?.sort((a, b) => b.number - a.number)?.[0]?.number - 1
                    ]?.name
                  }
                </Points>
              </Row>
            );
          })}
      </Rows>
    </Main>
  );
}
