import React from "react";
import styled from "styled-components";

const Main = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #919191;
`;
const Top = styled.div`
  display: flex;
`;
const Rows = styled.div`
  display: flex;
  flex-direction: column;
`;
const Row = styled.div`
  display: flex;
  padding-bottom: 5px;
  font-size: 1.1em;
  color: ${(props) => (props.selected ? "#fecc43" : "")};
`;
const Position = styled.div`
  width: 50px;
`;
const Name = styled.div`
  width: 200px;
`;
const Points = styled.div`
  width: 50px;
  margin-left: auto;
`;
const SubTitle = styled.div`
  font-weight: 100;
`;
export default function SmallGroup({ group, groupName, type, display }) {
  let rounds = [];
  debugger
  if (type === "final") {
    rounds = group?.rounds?.filter((r) => r.round?._id === display?.round?._id);
  } else {
    rounds = group?.rounds?.filter((r) => r.status === "finished");
  }

  return (
    <Main>
      <div>{groupName}</div>{" "}
      <Top>
        <SubTitle>
          {type === "final" && <span>As it stands (Live Standings)</span>}
          {type === "qualifying" && rounds.length > 0 && (
            <span>
              After {rounds.length} {rounds.length === 1 ? "Event" : "Events"}
            </span>
          )}
        </SubTitle>

        {rounds.length > 0 && <Points>PTS</Points>}
      </Top>
      <Rows>
        {rounds?.[rounds.length - 1]?.standings
          ?.sort((a, b) => a.overall_position - b.overall_position)
          ?.map((row) => {
            return (
              <Row selected={row.overall_position === 1}>
                <Position>
                  {row.overall_status}
                  {row.withdrew != 1 &&
                    row.overall_status != "WD" &&
                    row.overall_position}
                  .
                </Position>
                <Name>
                  {row.player.first_name[0]}. {row.player.last_name}
                </Name>

                <Points>{row.total_points}</Points>
              </Row>
            );
          })}
        {rounds.length === 0 &&
          group?.rounds?.[0]?.standings?.map((row) => {
            return (
              <Row>
                <Name>
                  {row.player.first_name[0]}. {row.player.last_name}
                </Name>
              </Row>
            );
          })}
      </Rows>
    </Main>
  );
}
