import React from "react";
import LeftBarImage from "../../images/leftbar.png";
import styled from "styled-components";
const Main = styled.div`
  display: flex;
  min-width: 400px;
  background-color: #b300d9;
  border-right: 0px solid #202b63;
`;
export default function LeftBar() {
  return (
    <Main>
      <img src={LeftBarImage} alt="Sponsors" />
    </Main>
  );
}
