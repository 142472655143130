import React from "react";
import styled from "styled-components";

const Main = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 40px;
`;
const Top = styled.div`
  display: flex;
  font-size: 25px;
  font-weight: 100;
`;
const Rows = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 20px;
`;
const Row = styled.div`
  display: flex;
  padding-bottom: 5px;
  font-size: 1.2em;
  color: ${(props) => (props.selected ? "#fecc43" : "")};
`;
const Position = styled.div`
  width: 70px;
`;
const Name = styled.div`
  width: 570px;
`;
const Points = styled.div`
  /* width: 50px; */
  margin-left: auto;
`;
const Country = styled.div`
  width: 100px;
`;
const Objects = styled.div`
  width: 400px;
  display: flex;
  justify-content: center;
`;

const Order = styled.div`
  width: 120px;
  text-align: center;
`;
export default function RepsGroup({ event, scoring }) {
  debugger;
  return (
    <Main>
      <Top>
        <Position>#</Position>
        <Order>Order</Order>
        <Order>Lane</Order>
        <Name></Name>
        <Country></Country>
        <Objects>{event.unit_name || "Reps"}</Objects>
      </Top>
      <Rows>
        {scoring &&
          scoring &&
          scoring
            ?.filter(p => p.overall_status !== 'WD')
            .sort((a, b) => {
              if ((a?.position || 999) > (b?.position || 999)) {
                return 1;
              } else if ((a?.position || 999) < (b?.position || 999)) {
                return -1;
              }

              if (parseInt(a?.scoring?.order || 999) > parseInt(b?.scoring?.order || 999)) {
                return 1
              } else if (parseInt(a?.scoring?.order || 999) < parseInt(b?.scoring?.order || 999)) {
                return -1
              }
              if (a?.scoring?.lane > b?.scoring?.lane) {
                return 1
              } else if (a?.scoring?.lane < b?.scoring?.lane) {
                return -1
              }
            })
            ?.map((row, index) => {
              return (
                <Row>
                  <Position>
                    {row.withdrew == 1 && "WD"}
                    {row.withdrew != 1 && row.tied && "T"}
                    {row.withdrew != 1 && row.position}.
                  </Position>

                  <Order>{row.scoring?.order}</Order>
                  <Order>{row.scoring?.lane}</Order>
                  <Name>
                    {row.player.first_name} {row.player.last_name}
                  </Name>
                  <Country>{row.player.nationality}</Country>
                  <Objects>{row.scoring?.reps}</Objects>
                </Row>
              );
            })}
      </Rows>
    </Main>
  );
}
