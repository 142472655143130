import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { DataContext } from "../../contexts/DataContext";
import moment from "moment";
const Main = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
`;
const Top = styled.div`
  display: flex;
  font-size: 0.6em;
  font-weight: 100;
  margin-bottom: 0.5em;
`;
const Rows = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 28px;
`;
const Row = styled.div`
  display: flex;
  padding-bottom: ${(props) => (props.smaller ? "2px" : "7px")};
  font-size: ${(props) => (props.smaller ? "0.6em" : "0.8em")};
`;
const Position = styled.div`
  width: 70px;
`;
const Name = styled.div`
  width: 240px;
`;
const Points = styled.div`
  width: 180px;
`;
const Country = styled.div`
  width: 100px;
`;
const Objects = styled.div`
  min-width: 180px;
  display: flex;
  justify-content: center;
`;
const Table = styled.div`
  display: flex;
`;
export default function LeadersLoadingGroup({ scoring }) {
  if (scoring?.length > 0) {
    console.table(scoring);
    debugger;
  }
  function sort(a, b) {
    let objects_1 = a.scoring?.objects?.filter((o) => o.success)?.length || 0;
    let objects_2 = b.scoring?.objects?.filter((o) => o.success)?.length || 0;
    if (objects_1 < objects_2) {
      return 1;
    } else if (objects_1 > objects_2) {
      return -1;
    }

    let distance_1 = parseFloat(a.scoring?.distance) || 0;
    let distance_2 = parseFloat(b.scoring?.distance) || 0;
    if (distance_1 < distance_2) {
      return 1;
    } else if (distance_1 > distance_2) {
      return -1;
    }

    let time_1 = moment(a.scoring?.time, "mm:ss.SS").valueOf();
    let time_2 = moment(b.scoring?.time, "mm:ss.SS").valueOf();
    if (time_1 > time_2) {
      return 1;
    } else if (time_1 < time_2) {
      return -1;
    } else {
      return 0;
    }
  }

  return (
    <Main>
      <Table>
        <Rows>
          <Top>
            <Position></Position>
            <Name></Name>

            <Objects>Objects</Objects>
            <Points>Time</Points>
          </Top>
          {scoring
            ?.sort(sort)
            ?.slice(0, 10)
            ?.map((row, index) => {
              debugger;
              return (
                <Row>
                  <Position>{index + 1}.</Position>
                  <Name>
                    {row.player?.first_name[0]}. {row.player?.last_name}
                  </Name>
                  {/* <Country>{row.player.nationality.code}</Country> */}
                  <Objects>
                    {row.scoring?.objects?.filter((o) => o.success)?.length}
                  </Objects>
                  <Points>{row?.scoring?.time}</Points>
                </Row>
              );
            })}
        </Rows>
        <Rows smaller>
          <Top>
            <Position></Position>
            <Name style={{ width: "120px" }}></Name>
            <Objects>Objects</Objects>
            <Points>Time</Points>
          </Top>
          {scoring
            ?.sort(sort)
            .slice(10)
            .slice(-20)
            .map((row, index) => {
              return (
                <Row smaller>
                  <Position>{index + 11}.</Position>
                  <Name style={{ width: "120px", fontSize: "12px" }}>
                    {row.player.first_name[0]}. {row.player.last_name}
                  </Name>
                  {/* <Country>{row.player.nationality.code}</Country> */}
                  <Objects>
                    {row?.scoring?.objects?.filter((o) => o.success)?.length}
                  </Objects>
                  <Points>{row?.scoring?.time}</Points>
                </Row>
              );
            })}
        </Rows>
      </Table>
    </Main>
  );
}
