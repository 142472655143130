import React from "react";
import styled from "styled-components";

const Main = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 40px;
`;
const Top = styled.div`
  display: flex;
  font-size: 25px;
  font-weight: 100;
`;
const Rows = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 28px;
`;
const Row = styled.div`
  display: flex;
  padding-bottom: 5px;
  font-size: 1.2em;
  color: ${(props) => (props.selected ? "#fecc43" : "")};
`;
const Position = styled.div`
  width: 90px;
`;
const Name = styled.div`
  width: 500px;
`;
const Points = styled.div`
  /* width: 50px; */
  margin-left: auto;
`;
const Country = styled.div`
  width: 150px;
`;
const Objects = styled.div`
  width: 150px;
  display: flex;
  justify-content: center;
`;
export default function DistanceGroup({ group, scoring }) {
  return (
    <Main>
      <Top>
        <Name>Order</Name>
        <Name>Group {group && group.name}</Name>
        <Position></Position>
        <Country></Country>
        <Objects>Meters</Objects>
      </Top>
      <Rows>
        {scoring &&
          scoring &&
          scoring
            .filter((s) => s.withdrew != 1)
            .sort((a, b) => a.position - b.position)
            .map((row, index) => {
              return (
                <Row selected={row.position === 1}>
                  <Position>
                    {row.withdrew == 1 && "WD"}
                    {row.withdrew != 1 && row.tied && "T"}
                    {row.withdrew != 1 && row.position}.
                  </Position>
                  <Name>
                    {row.player.first_name} {row.player.last_name}
                  </Name>
                  <Country>{row.player.nationality.code}</Country>
                  <Objects>{row.distance}</Objects>
                </Row>
              );
            })}
      </Rows>
    </Main>
  );
}
