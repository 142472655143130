import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { DataContext } from "../../contexts/DataContext";
import LoadingGroup from "../Group/LoadingGroup";
import RepsGroup from "../Group/RepsGroup";
import TruckPullGroup from "../Group/TruckPullGroup";
import LeadersLoadingGroup from "../Leaders/LeadersLoadingGroup";
import LeadersTruckPullGroup from "../Leaders/LeadersTruckPullGroup";
import LeadersRepsGroup from "../Leaders/LeadersRepsGroup";
import RepsAndTimeGroup from "../Group/RepsAndTimeGroup";
import LeadersRepsAndTimeGroup from "../Leaders/LeadersRepsAndTimeGroup";
import LastManStandingGroup from "../Group/LastManStandingGroup";
import DistanceGroup from "../Group/DistanceGroup";
import LeadersDistanceGroup from "../Leaders/LeadersDistanceGroup";
import WSMLogo from "../../images/WSMLogo2.png";
import MaxTimeGroup from "../Group/MaxTime";
import LeadersMaxTime from "../Leaders/LeadersMaxTime";
import ReignLogo from "../../images/REIGN.png";
import KnaackLogo from "../../images/KNAACK.png";
import BFGoodrichLogo from "../../images/BFGOODRICH.png";
import StoneOffGroup from "../Group/StoneOffGroup";
import MaxWeightGroup from "../Group/MaxWeight";
import MaxDistanceGroup from "../Group/MaxDistanceGroup";
import LeadersMaxDistanceGroup from "../Leaders/LeadersMaxDistanceGroup";

const Main = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 1140px;
`;
const Half = styled.div`
  display: flex;
  flex-direction: column;
  height: 40%;
  padding: 40px;
  box-sizing: border-box;
  width: 100%;
`;
const Title = styled.div`
  display: flex;
  font-size: 2.5em;
`;
const Split = styled.div`
  height: 2px;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 45%,
    rgba(255, 255, 255, 0) 100%
  );
  width: 80%;
`;

const Spliter = styled.div`
  margin-top: -4px;
  margin-left: 0.5em;
  margin-right: 0.5em;
`;
const SponsorLogo = styled.div`
  margin-left: auto;
`;

const GroupName = styled.div`
  font-size: 40px;
`;

const HoldingDiv = styled.div`
  width: 1511px;
  height: 1084px;
  display: flex;
  justify-content: center;
`;
export default function Centre() {
  const { competition, getDisplay } = useContext(DataContext);
  const [scoring, setScoring] = useState([]);
  const [event, setEvent] = useState();
  const [display, setDisplay] = useState();
  const [rounds, setRounds] = useState();

  useEffect(() => {
    let interval = setInterval(() => {
      getDisplay({}).then((data) => {
        setDisplay(
          data?.find(
            (d) => d.competition?._id === competition?.[0]?.competition?._id
          )
        );
      });
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [competition, getDisplay]);

  let round = competition
    ?.find((g) => g.group?._id === display?.group?._id)
    ?.rounds?.find((r) => r.round?.event?._id === display?.event?._id);

  let group = competition?.find(
    (g) => g.group?._id === display?.group?._id
  )?.group;
  let leaders = [];

  competition &&
    display &&
    competition.forEach((c, index) => {
      let standings = c?.rounds?.find(
        (r) => r.round?.event?._id === display?.event?._id
      )?.standings;

      leaders.push(...(standings || []));
    });

  return (
    <Main>
      {!display?.holding && (
        <>
          <Half>
            <Title>
              Event {round?.round?.order} <Spliter>|</Spliter>{" "}
              {round?.round?.event?.name}
              {round?.round?.event?.name?.indexOf('BFGoodrich') >-1 &&  <SponsorLogo>
                <img height="30" src={BFGoodrichLogo} />
              </SponsorLogo> }
             {round?.round?.event?.name?.indexOf('Reign') >-1 &&  <SponsorLogo>
                <img height="70" src={ReignLogo} />
              </SponsorLogo> }
              {round?.round?.event?.name?.indexOf('KNAACK') >-1 && <SponsorLogo>
                <img height="70" src={KnaackLogo} />
              </SponsorLogo>
              }
            </Title>
            <GroupName>
              {group?.name === "6"
                ? "Final Group"
                : group?.name && "Group " + group?.name}
            </GroupName>
            {round?.round?.event?.event_type === "time_and_distance" && (
              <TruckPullGroup scoring={round?.standings} />
            )}
            {round?.round?.event?.event_type === "loading" && (
              <LoadingGroup scoring={round?.standings} />
            )}
            {round?.round?.event?.event_type === "reps" && (
              <RepsGroup
                scoring={round?.standings}
                event={round?.round?.event}
              />
            )}
             {round?.round?.event?.event_type === "max_distance" && (
              <MaxDistanceGroup
                scoring={round?.standings}
                event={round?.round?.event}
              />
            )}
            {round?.round?.event?.event_type === "reps_and_time" && (
              <RepsAndTimeGroup
                scoring={round?.standings}
                event={round?.round?.event}
              />
            )}
            {round?.round?.event?.event_type === "stone_off" && (
              <StoneOffGroup
                scoring={round?.standings}
                event={round?.round?.event}
              />
            )}
            {round?.round?.event?.event_type === 11 && (
              <DistanceGroup scoring={round?.standings} />
            )}
            {round?.round?.event?.event_type === "max_time" && (
              <MaxTimeGroup scoring={round?.standings} />
            )}
            {round?.round?.event?.event_type === "max_weight" && (
              <MaxWeightGroup
                scoring={round?.standings}
                event={round?.round?.event}
              />
            )}
          </Half>
          {/* {round?.round?.event?.event_type !== 5 && <Split></Split>} */}
          {group?.name !== "6" &&

          <Half>
            {/* <Title></Title> */}
            {round?.round?.event?.event_type === "time_and_distance" && (
              <LeadersTruckPullGroup group={group} scoring={leaders} />
            )}
            {round?.round?.event?.event_type === "loading" && (
              <LeadersLoadingGroup group={group} scoring={leaders} />
            )}
            {/* {event && event.type === 3 && (
              <LeadersRepsGroup group={group} scoring={leaders} />
            )} */}
            {round?.round?.event?.event_type === "reps" && (
              <LeadersRepsGroup scoring={leaders} event={round?.round?.event} />
            )}
             {round?.round?.event?.event_type === "max_distance" && (
              <LeadersMaxDistanceGroup scoring={leaders} event={round?.round?.event} />
            )}

            {round?.round?.event?.event_type === "reps_and_time" && (
              <LeadersRepsAndTimeGroup
                scoring={leaders}
                event={round?.round?.event}
              />
            )}
            {round?.round?.event?.event_type === "max_time" && (
              <LeadersMaxTime scoring={leaders} event={round?.round?.event} />
            )}
            {/* {round?.round?.event?.event_type === 11 && (
              <LeadersDistanceGroup group={group} scoring={leaders} />
            )}  */}
          </Half>}
        </>
      )}
      {display && display.holding && (
        <HoldingDiv>
          <img height="500" style={{ marginTop: "250px" }} src={WSMLogo} />
        </HoldingDiv>
      )}
    </Main>
  );
}
