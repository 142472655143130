import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { DataContext } from "../../contexts/DataContext";
import moment from "moment";
const Main = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
`;
const Top = styled.div`
  display: flex;
  font-size: 0.6em;
  font-weight: 100;
  margin-bottom: 0.5em;
`;
const Rows = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 28px;
`;
const Row = styled.div`
  display: flex;
  padding-bottom: ${(props) => (props.smaller ? "2px" : "7px")};
  font-size: ${(props) => (props.smaller ? "0.6em" : "0.8em")};
`;
const Position = styled.div`
  width: 70px;
`;
const Name = styled.div`
  width: 200px;
`;
const Points = styled.div`
  width: 150px;
  font-size: 20px;
`;
const Gap = styled.div`
  width: 100px;
  font-size: 20px;
`;
const Diff = styled.div`
  width: 100px;
  font-size: 20px;
`;
const Country = styled.div`
  width: 100px;
`;
const Objects = styled.div`
  min-width: 140px;
  display: flex;
  justify-content: center;
`;
const Table = styled.div`
  display: flex;
`;
export default function LeadersMaxTime({ group, scoring }) {
  function sort(a, b) {
    let time_1 = moment(a.scoring?.time || "00:00.00", "mm:ss.SS").valueOf();
    let time_2 = moment(b.scoring?.time || "00:00.00", "mm:ss.SS").valueOf();
    if (time_1 > time_2) {
      return -1;
    } else if (time_1 < time_2) {
      return 1;
    } else {
      return 0;
    }
  }
  return (
    <Main>
      <Top>
        <Position></Position>

        <Name></Name>

        {/* <Objects>Reps</Objects> */}
        <Points>Time</Points>
        <Gap>Gap</Gap>
        <Diff>Diff</Diff>
      </Top>
      <Table>
        <Rows>
          {scoring
            ?.sort(sort)
            ?.slice(0, 10)
            .map((row, index) => {
              let diff = '';
              let gap = '';
              if (index > 0) {
                let time_1 = moment(scoring?.[index]?.scoring?.time || '00:00.00', 'mm:ss.SS')
                let time_2 = moment(scoring?.[index - 1]?.scoring?.time || '00:00.00', 'mm:ss.SS')
                let time_3 = moment(scoring?.[0]?.scoring?.time || '00:00.00', 'mm:ss.SS')
                var duration = moment.duration(time_2.diff(time_1));
                diff = duration.asSeconds().toFixed(2);

                var duration_2 = moment.duration(time_3.diff(time_1));
                gap = duration_2.asSeconds().toFixed(2);
              }


              return (
                <Row>
                  <Position>{index + 1}.</Position>
                  <Name>{row.player?.first_name[0]}. {row.player.last_name}</Name>
                  {/* <Country>{row.player.nationality.code}</Country> */}
                  {/* <Objects>{row.reps}</Objects> */}
                  <Points>{row.scoring?.time}</Points>
                  <Gap>{row.scoring?.time && (gap || '')}</Gap>
                  <Diff>{row.scoring?.time && (diff || '')}</Diff>

                </Row>
              );
            })}
        </Rows>
        <Rows smaller>
          {scoring
            ?.sort(sort)
            .slice(10)
            .slice(-20)
            .map((row, index) => {
              return (
                <Row smaller>
                  <Position>{index + 11}.</Position>
                  <Name>{row.player?.first_name[0]}. {row.player.last_name}</Name>
                  {/* <Country>{row.player.nationality.code}</Country> */}
                  <Objects>{row.scoring?.reps}</Objects>
                  <Points>{row.scoring?.time}</Points>
                </Row>
              );
            })}
        </Rows>
      </Table>
    </Main>
  );
}
