import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { DataContext } from "../../contexts/DataContext";
import moment from "moment";
const Main = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
`;
const Top = styled.div`
  display: flex;
  font-size: 0.6em;
  font-weight: 100;
  margin-bottom: 0.5em;
`;
const Rows = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 28px;
`;
const Row = styled.div`
  display: flex;
  padding-bottom: ${(props) => (props.smaller ? "2px" : "7px")};
  font-size: ${(props) => (props.smaller ? "0.6em" : "0.8em")};
`;
const Position = styled.div`
  width: 70px;
`;
const Name = styled.div`
  width: 170px;
`;
const Points = styled.div`
  width: 150px;
`;
const Gap = styled.div`
  width: 85px;
`;
const Diff = styled.div`
  width: 85px;
`;

const Country = styled.div`
  width: 100px;
`;
const Objects = styled.div`
  min-width: 120px;
  display: flex;
  justify-content: center;
`;
const Table = styled.div`
  display: flex;
`;
export default function LeadersTruckPullGroup({ event, scoring }) {
  function sort(a, b) {
    let time_1 = moment(a.scoring?.time || "9:00.00", "mm:ss.SS").valueOf();
    let time_2 = moment(b.scoring?.time || "9:00.00", "mm:ss.SS").valueOf();

    if (time_1 > time_2) {
      return 1;
    } else if (time_1 < time_2) {
      return -1;
    }

    let distance_1 = parseFloat(a.scoring?.distance) || 0;
    let distance_2 = parseFloat(b.scoring?.distance) || 0;
    if (distance_1 < distance_2) {
      return 1;
    } else if (distance_1 > distance_2) {
      return -1;
    } else {
      return 0;
    }
  }

  return (
    <Main>
      <Top>
        <Position></Position>
        <Name></Name>

        <Objects>Distance (m)</Objects>
        <Points>Time</Points>
        <Diff>Diff</Diff>
        <Gap>Gap</Gap>
      </Top>
      <Table>
        <Rows>
          {scoring
            ?.sort(sort)
            ?.slice(0, 10)
            ?.map((row, index) => {
              let diff = "";
              let gap = "";
              if (index > 0) {
                if (row?.scoring?.reps === scoring?.[0]?.scoring?.reps) {
                  let time_1 = moment(
                    scoring?.[index]?.scoring?.time || "00:00.00",
                    "mm:ss.SS"
                  );
                  let time_2 = moment(
                    scoring?.[index - 1]?.scoring?.time || "00:00.00",
                    "mm:ss.SS"
                  );
                  let time_3 = moment(
                    scoring?.[0]?.scoring?.time || "00:00.00",
                    "mm:ss.SS"
                  );
                  var duration = moment.duration(time_1.diff(time_2));
                  diff = duration.asSeconds().toFixed(2);
                  if (diff > 0) {
                    diff = "+" + diff;
                  } else {
                    diff = "";
                  }
                  var duration_2 = moment.duration(time_1.diff(time_3));
                  gap = duration_2.asSeconds().toFixed(2);
                  if (gap > 0) {
                    gap = "+" + gap;
                  } else {
                    gap = "";
                  }
                }
              }

              return (
                <Row>
                  <Position>{index + 1}.</Position>
                  <Name>
                    {row.player?.first_name[0]}. {row.player.last_name}
                  </Name>
                  {/* <Country>{row.player.nationality.code}</Country> */}
                  <Objects>{row.scoring?.distance}</Objects>
                  <Points>{row.scoring?.time}</Points>
                  <Diff>{diff}</Diff>
                  <Gap>{gap}</Gap>
                </Row>
              );
            })}
        </Rows>
        <Rows smaller>
          {scoring
            ?.sort(sort)
            .slice(10)
            .slice(-20)
            .map((row, index) => {
              return (
                <Row smaller>
                  <Position>{index + 11}.</Position>
                  <Name>
                    {row.player?.first_name[0]}. {row.player.last_name}
                  </Name>
                  {/* <Country>{row.player.nationality.code}</Country> */}
                  <Objects>{row.scoring?.distance}</Objects>
                  <Points>{row.scoring?.time}</Points>
                </Row>
              );
            })}
        </Rows>
      </Table>
    </Main>
  );
}
