/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import WSMLogo from "../../images/WSMLogo__.png";
import WSMhashTag from "../../images/wsm.png";
import WSMPartners from "../../images/WSMPartners.png";
import styled from "styled-components";
import SmallGroup from "../Group/SmallGroup";
import { DataContext } from "../../contexts/DataContext";
import { getDataFromTree } from "@apollo/client/react/ssr";

const Main = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 327px;
  border-left: 0px solid #4fc0d8;
  padding: 20px;
  background-color: #100ee1;
  position: relative;
  height: 1080px;
  box-sizing: border-box;
`;
const Logo = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 5px;
`;
const Title = styled.div`
  font-size: 1.5em;
  font-weight: 100;
`;
const SubTitle = styled.div`
  font-size: 1.2em;
  font-weight: 100;
`;
const HashTag = styled.div`
  font-size: 6em;
  font-weight: bold;
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 0px;
  right: 0px;
`;

let groupIndex = 0;
export default function RightBar() {
  const { getDisplay, competition } = useContext(DataContext);
  const [standings, setStandings] = useState([]);
  const [display, setDisplay] = useState();
  const [groups, setGroups] = useState();
  const [events, setEvents] = useState();
  const [rounds, setRounds] = useState();

  useEffect(() => {
    let interval2 = setInterval(() => {
      updateIndex();
    }, 30000);
    return () => {
      clearInterval(interval2);
    };
  }, []);

  useEffect(() => {
    getData();
  }, [competition]);

  function getData() {
    getDisplay().then((data) => {
      setDisplay(
        data?.find(
          (d) => d.competition?._id === competition?.[0]?.competition?._id
        )
      );
    });
  }

  function updateIndex() {
    if (groupIndex < 3) {
      groupIndex += 3;
    } else {
      groupIndex = 0;
    }
  }

  return (
    !display?.holding && (
      <Main>
        <div>
          <Logo>
            <img src={WSMLogo} alt="WSM Logo" style={{ height: "100px" }} />
          </Logo>
          {display?.round?.type === "qualifying" && (
            <>
              <Title>Standings</Title>
              {[...(competition || [])]
                ?.sort((a, b) => {
                  return parseInt(a?.group?.name) - parseInt(b?.group?.name);
                })
                ?.filter((g) => {
                  return g.group?.name !== "6";
                })
                ?.splice(groupIndex, 3)
                ?.map((g) => {
                  return (
                    <SmallGroup 
                    type={display?.round?.type}
                      group={g}
                      groupName={"Group " + g?.group?.name}
                    />
                  );
                })}
            </>
          )}
          {display?.round?.type === "final" && (
            <>
              <Title>Standings</Title>
              {[...(competition || [])]
                ?.filter((g) => {
                  return g.group?.name === "6";
                })
                ?.map((g) => {
                  return (
                    <SmallGroup
                      group={g}
                      groupName={"Final Group"}
                      type={display?.round?.type}
                      display={display}
                    />
                  );
                })}
            </>
          )}
        </div>
        <HashTag>
          <img src={WSMhashTag} width="332px" alt="WSM Hashtag" />
        </HashTag>
      </Main>
    )
  );
}
