import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { DataContext } from "../../contexts/DataContext";

const Main = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 40px;
`;
const Top = styled.div`
  display: flex;
  font-size: 25px;
  font-weight: 100;
`;
const Rows = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 28px;
`;
const Row = styled.div`
  display: flex;
  padding-bottom: 5px;
  font-size: 1.2em;
  align-items: center;
  /* :nth-child(1) {
  color: ${(props) => (props.smaller ? "" : "#fecc43")};
  } */
`;
const Position = styled.div`
  min-width: 70px;
`;
const Name = styled.div`
  min-width: 300px;
  align-items: center;
  display: flex;
  color: ${(props) => (props.selected ? "#fecc43" : "")};
`;
const Points = styled.div`
  width: 180px;
  /* margin-left: auto; */
`;
const Country = styled.div`
  width: 100px;
`;
const Objects = styled.div`
  min-width: 70px;
  min-height: 70px;
  display: flex;
  justify-content: center;
  background-color: #fecc43;
  margin-right: 20px;
  align-items: center;
  color: #2f2f2f;
  font-weight: bold;
`;
const Left = styled.div`
  display: flex;
  min-width: 200px;
  margin-right: 10px;
`;
const Right = styled.div`
  display: flex;
  min-width: 200px;
`;
const Group = styled.div`
  display: flex;
  min-width: 200px;
`;
const Players = styled.div`
  display: flex;
  align-items: center;
`;
const Split = styled.div`
  height: 1px;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 45%,
    rgba(255, 255, 255, 0) 100%
  );
  width: 106%;
  margin: 20px;
  margin-left: -20px;
`;
export default function LastManStandingGroup({ group, scoring }) {
  const { getPlayer, getGroup } = useContext(DataContext);
  const [overall, setOverall] = useState();
  useEffect(() => {
    getGroup({}).then((groups) => {
      getPlayer({}).then((data) => {
        setOverall(
          scoring.map((score) => {
            return {
              group: groups.find((g) => g._id === score.group),
              standings: score.standings.map((p) => {
                return {
                  ...p,
                  player: data.find((player) => player._id === p.player),
                };
              }),
            };
          })
        );
      });
    });
  }, [scoring]);
  return (
    <Main>
      <Rows>
        {overall &&
          overall &&
          overall.map((row, index) => {
            let leftPlayer = row.standings.find(
              (p) => p.order == 1 && p.lane == "L"
            );
            let rightPlayer = row.standings.find(
              (p) => p.order == 1 && p.lane == "R"
            );

            return (
              leftPlayer &&
              rightPlayer && (
                <>
                  <Row>
                    <Group>Group {row.group.name}</Group>
                    <Players>
                      <Left>
                        <Name selected={leftPlayer.distance == 1}>
                          {leftPlayer.player.first_name}{" "}
                          {leftPlayer.player.last_name}
                        </Name>
                        <Objects>{leftPlayer.reps}</Objects>
                      </Left>
                      <Right>
                        <Objects>{rightPlayer.reps}</Objects>
                        <Name selected={rightPlayer.distance == 1}>
                          {rightPlayer.player.first_name}{" "}
                          {rightPlayer.player.last_name}
                        </Name>
                      </Right>
                    </Players>
                  </Row>
                  <Split />
                </>
              )
            );
          })}
      </Rows>
    </Main>
  );
}
