import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { DataContext } from "../../contexts/DataContext";
import moment from "moment";
const Main = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
`;
const Top = styled.div`
  display: flex;
  font-size: 1.2em;
  font-weight: 100;
`;
const Rows = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 28px;
`;
const Row = styled.div`
  display: flex;
  padding-bottom: ${(props) => (props.smaller ? "2px" : "7px")};
  font-size: ${(props) => (props.smaller ? "0.6em" : "0.8em")};
`;
const Position = styled.div`
  width: 70px;
`;
const Name = styled.div`
  width: 240px;
`;
const Points = styled.div`
  width: 150px;
`;
const Country = styled.div`
  width: 100px;
`;
const Objects = styled.div`
  min-width: 340px;
  display: flex;
  justify-content: center;
`;
const Table = styled.div`
  display: flex;
`;
export default function LeadersDistanceGroup({ group, scoring }) {
  const { getPlayer } = useContext(DataContext);
  const [overall, setOverall] = useState();
  useEffect(() => {
    getPlayer({}).then((data) => {
      setOverall(
        scoring
          .flatMap((score) => {
            return score.standings.map((p) => {
              return {
                ...p,
                player: data.find((player) => player._id === p.player),
              };
            });
          })
          .sort((a, b) => {
            return b.distance - a.distance;
          })
          .map((s, index) => {
            return { ...s, position: index + 1 };
          })
      );
    });
  }, [scoring]);

  return (
    <Main>
      <Top>
        <Name></Name>
        {/* <Position></Position> */}

        <Objects>Meters</Objects>
      </Top>
      <Table>
        <Rows>
          {overall &&
            overall &&
            overall
              .filter((a) => a.withdrew != 1)
              .sort((a, b) => a.position - b.position)
              .slice(0, 10)
              .map((row, index) => {
                return (
                  <Row>
                    {/* <Position>{row.position}.</Position> */}
                    <Name>{row.player.last_name}</Name>
                    {/* <Country>{row.player.nationality.code}</Country> */}
                    <Objects>{row.distance}</Objects>
                  </Row>
                );
              })}
        </Rows>
        <Rows smaller>
          {overall &&
            overall.length > 10 &&
            overall
              .sort((a, b) => a.position - b.position)
              .slice(10)
              .slice(-15)
              .map((row, index) => {
                return (
                  <Row smaller>
                    {/* <Position>{row.position}.</Position> */}
                    <Name>{row.player.last_name}</Name>
                    {/* <Country>{row.player.nationality.code}</Country> */}
                    <Objects>{row.distance}</Objects>
                  </Row>
                );
              })}
        </Rows>
      </Table>
    </Main>
  );
}
