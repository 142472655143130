import React from "react";
import styled from "styled-components";
import moment from "moment";
const Main = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 40px;
`;
const Top = styled.div`
  display: flex;
  font-size: 25px;
  font-weight: 100;
`;
const Rows = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 20px;
`;
const Row = styled.div`
  display: flex;
  padding-bottom: 5px;
  font-size: 1.2em;
  :nth-child(1) {
    color: ${(props) => (props.smaller ? "" : "#fecc43")};
  }
`;
const Position = styled.div`
  width: 40px;
`;
const Name = styled.div`
  width: 320px;
`;
const Points = styled.div`
  margin-left: auto;
  width: 100px;
`;

const Time = styled.div`
  margin-left: auto;
`;

const Country = styled.div`
  width: 100px;
`;
const Objects = styled.div`
  width: 100px;
  display: flex;
  justify-content: center;
`;

const Order = styled.div`
  min-width: 90px;
  text-align: center;
`;
const Gap = styled.div`
  width: 100px;
  font-size: 15px;
  text-align: right;
`;
const Diff = styled.div`
  width: 100px;
  font-size: 20px;
  text-align: right;
`;
export default function RepsAndTimeGroup({ event, scoring }) {
  return (
    <Main>
      <Top>
        <Position>#</Position>
        <Order>Order</Order>
        <Order>Lane</Order>
        <Name></Name>

        <Country></Country>
        <Objects>{event?.unit_name ?? "Reps"}</Objects>
        <Time>Time</Time>
        <Diff>Diff</Diff>
        <Gap>Gap</Gap>
        {/* <Points>Diff</Points> */}
      </Top>
      <Rows>
        {scoring?.filter(p => p.overall_status !== 'WD')
          ?.sort((a, b) => {
            if ((a?.position || 999) > (b?.position || 999)) {
              return 1;
            } else if ((a?.position || 999) < (b?.position || 999)) {
              return -1;
            }

            if (parseInt(a?.scoring?.order || 999) > parseInt(b?.scoring?.order || 999)) {
              return 1
            } else if (parseInt(a?.scoring?.order || 999) < parseInt(b?.scoring?.order || 999)) {
              return -1
            }
            if (a?.scoring?.lane > b?.scoring?.lane) {
              return 1;
            } else if (a?.scoring?.lane < b?.scoring?.lane) {
              return -1;
            }
          })
          ?.map((row, index) => {
            let diff = "";
            let gap = "";
            if (index > 0) {
              let time_1 = moment(
                scoring?.[index]?.scoring?.time || "00:00.00",
                "mm:ss.SS"
              );
              let time_2 = moment(
                scoring?.[index - 1]?.scoring?.time || "00:00.00",
                "mm:ss.SS"
              );
              let time_3 = moment(
                scoring?.[0]?.scoring?.time || "00:00.00",
                "mm:ss.SS"
              );
              if (row?.scoring?.reps === scoring?.[index - 1]?.scoring?.reps) {
                var duration = moment.duration(time_1.diff(time_2));
                diff = duration.asSeconds().toFixed(2);
                if (diff > 0) {
                  diff = "+" + diff;
                }
              }
              if (row?.scoring?.reps === scoring?.[0]?.scoring?.reps) {
                var duration_2 = moment.duration(time_1.diff(time_3));
                gap = duration_2.asSeconds().toFixed(2);
                if (gap > 0) {
                  gap = "+" + gap;
                }
              } else {
                let diff_reps =
                  scoring?.[0]?.scoring?.reps - row?.scoring?.reps || 0;
                gap =
                  diff_reps +
                  " " +
                  (diff_reps === 1
                    ? event?.unit_name?.substring(
                        0,
                        event?.unit_name?.length - 1
                      )
                    : event?.unit_name);
              }
            }
            return (
              <Row>
                <Position>
                  {row.withdrew == 1 && "WD"}
                  {row.withdrew != 1 && row.tied && "T"}
                  {row.withdrew != 1 && row.position}.
                </Position>

                <Order>{row.scoring?.order}</Order>
                <Order>{row.scoring?.lane}</Order>
                <Name>
                  {row.player.first_name} {row.player.last_name}
                </Name>
                <Country>{row.player.nationality}</Country>
                <Objects>{row.scoring?.reps}</Objects>
                <Time>{row.scoring?.time}</Time>
                <Diff>{row.scoring?.time && (diff || "")}</Diff>
                <Gap>{gap || ""}</Gap>
                {/* <Diff>{row.scoring?.time && (diff || '')}</Diff> */}
              </Row>
            );
          })}
      </Rows>
    </Main>
  );
}
