import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { DataContext } from "../../contexts/DataContext";
import moment from "moment";
const Main = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
`;
const Top = styled.div`
  display: flex;
  font-size: 0.8em;
  font-weight: 100;
`;
const Rows = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 28px;
`;
const Row = styled.div`
  display: flex;
  padding-bottom: ${(props) => (props.smaller ? "2px" : "7px")};
  font-size: ${(props) => (props.smaller ? "0.6em" : "0.8em")};
`;
const Position = styled.div`
  width: 70px;
`;
const Name = styled.div`
  width: 200px;
`;
const Points = styled.div`
  width: 150px;
`;
const Diff = styled.div`
  width: 80px;
  font-size: 0.8em;
`;
const Gap = styled.div`
  width: 80px;
  font-size: 0.7em;
`;
const Country = styled.div`
  width: 100px;
`;
const Objects = styled.div`
  min-width: 95px;
  display: flex;
  justify-content: center;
`;
const Table = styled.div`
  display: flex;
`;
export default function LeadersRepsAndTimeGroup({ event, scoring }) {
  function sort(a, b) {
    let reps_1 = parseInt(a.scoring?.reps) || -1;
    let reps_2 = parseInt(b.scoring?.reps) || -1;
    if (reps_1 < reps_2) {
      return 1;
    } else if (reps_1 > reps_2) {
      return -1;
    }

    let time_1 = moment(a.scoring?.time, "mm:ss.SS").valueOf();
    let time_2 = moment(b.scoring?.time, "mm:ss.SS").valueOf();
    if (time_1 > time_2) {
      return 1;
    } else if (time_1 < time_2) {
      return -1;
    } else {
      return 0;
    }
  }

  return (
    <Main>
      <Top>
        <Position></Position>
        <Name></Name>
        <Objects>
          {event.event_type === "stone_off"
            ? "Stones"
            : event.unit_name || "Reps"}
        </Objects>
        <Points>Time</Points>
        <Diff>Diff</Diff>
        <Gap>Gap</Gap>
      </Top>
      <Table>
        <Rows>
          {scoring
            ?.sort(sort)
            ?.slice(0, 10)
            ?.map((row, index) => {
              let diff = "";
              let gap = "";
              if (index > 0) {
                let time_1 = moment(
                  scoring?.[index]?.scoring?.time || "00:00.00",
                  "mm:ss.SS"
                );
                let time_2 = moment(
                  scoring?.[index - 1]?.scoring?.time || "00:00.00",
                  "mm:ss.SS"
                );
                let time_3 = moment(
                  scoring?.[0]?.scoring?.time || "00:00.00",
                  "mm:ss.SS"
                );
                if (
                  row?.scoring?.reps === scoring?.[index - 1]?.scoring?.reps
                ) {
                  var duration = moment.duration(time_1.diff(time_2));
                  diff = duration.asSeconds().toFixed(2);
                  if (diff > 0) {
                    diff = "+" + diff;
                  }
                }
                if (row?.scoring?.reps === scoring?.[0]?.scoring?.reps) {
                  var duration_2 = moment.duration(time_1.diff(time_3));
                  gap = duration_2.asSeconds().toFixed(2);
                  if (gap > 0) {
                    gap = "+" + gap;
                  }
                } else {
                  let diff_reps =
                    scoring?.[0]?.scoring?.reps - row?.scoring?.reps || 0;
                  gap =
                    diff_reps +
                    " " +
                    (diff_reps === 1
                      ? event?.unit_name?.substring(
                          0,
                          event?.unit_name?.length - 1
                        )
                      : event?.unit_name);
                }
              }
              return (
                <Row>
                  <Position>{index + 1}.</Position>
                  <Name>{row.player.last_name}</Name>
                  {/* <Country>{row.player.nationality.code}</Country> */}
                  <Objects>{row.scoring?.reps}</Objects>
                  <Points>{row.scoring?.time}</Points>
                  <Diff>{diff}</Diff>
                  <Gap>{gap}</Gap>
                </Row>
              );
            })}
        </Rows>
        <Rows smaller>
          {scoring
            ?.sort(sort)
            .slice(10)
            .slice(-20)
            .map((row, index) => {
              return (
                <Row smaller>
                  <Position>{index + 11}.</Position>
                  <Name>{row.player.last_name}</Name>
                  {/* <Country>{row.player.nationality.code}</Country> */}
                  <Objects>{row.scoring?.reps}</Objects>
                  <Points>{row.scoring?.time}</Points>
                </Row>
              );
            })}
        </Rows>
      </Table>
    </Main>
  );
}
